<template>
  <div class="app-contianer">
    <div class="setLogon page-item">
      <div class="item">
        <div class="text">
          <span>真实姓名</span>
        </div>
        <div class="content">
          <input type="text" v-model="username">
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span>支付宝账户</span>
        </div>
        <div class="content">
          <input type="text" v-model="logonId">
        </div>
      </div>
    </div>

    <div class="submitBut" @click="submit()">提交</div>

  </div>
</template>

<script>
import { getToken } from '../../../api/index'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'setLogon',
  data() {
    return {
      logonId: '',
      username: '',
    }
  },
  computed: {
    ...mapState('user', ['custMsg']),
  },
  created() {
    this.logonId = this.custMsg.logonId
    this.username = this.custMsg.username
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    submit() {
      if (!this.username) {
        this.$toast('请填写真实姓名')
        return
      }
      if (!this.logonId) {
        this.$toast('请填写支付宝账户')
        return
      }
      getToken({
        trxCode: 'XF182',
        userName: this.username,
        logonId: this.logonId,
      }).then((res) => {
        if (res.custMsg) {
          this.$toast.success('绑定成功')
          this.getUserInfo()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.setLogon {
  .item {
    padding: 12px 0;
    display: flex;
    align-items: center;

    .text {
      display: inline-block;
      color: #333;
    }
    .content {
      flex: 1;
    }
    input {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
    }
  }

  .content input {
    border: none;
    text-align: right;
    color: #999;
  }
}
</style>